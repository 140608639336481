import { Injectable } from '@angular/core';
import { Constant } from '../constants/constant';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Validators_Patterns } from '../constants/validators';
import { BikeStatusService } from './bikestatus.service';
import { TranslateService } from '@ngx-translate/core';
import { FileType, SnackBar } from '../constants/storageitem';
import { HttpClient } from '@angular/common/http';
import { environments } from 'src/environments/environment';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  marker!: any;

  public userDetails = new BehaviorSubject<any>('');
  getUserDetails:any = this.userDetails.asObservable();

  private userDetailSubject = new BehaviorSubject<string>(null);
  editUserId$ = this.userDetailSubject.asObservable();


  constructor(private apiService: ApiService,  private storage : StorageService, private snackBar : BikeStatusService, private translate : TranslateService, private http : HttpClient) {
    const userEditId = JSON.parse(this.storage.getObject('userEditId'));

    if (userEditId) {
      this.userDetailSubject.next(userEditId);
    }
  }

  setUserId(userId) {
    this.userDetailSubject.next(userId);
    this.storage.setObject('userEditId', userId);
  }

  getLanguageList() {
    return this.apiService.getData(Constant.languageList);
  }

  userPersonalDetails(payload: any) {
    return this.apiService.postData(Constant.userRegister, payload);
  }

  userAddressDetails(payload: any) {
    return this.apiService.postData(Constant.userAddress, payload);
  }

  userAllList(userId) {
    return this.apiService.getData(Constant.userList + '/' + userId);
  }

  getUserAddress(userId: any){
    return this.apiService.getData(Constant.userAddresses + '/' + userId);
  }

  getUserRole() {
    return this.apiService.getData(Constant.userRole)
  }

  getPostCode(zipCode: any) {
    return this.apiService.getData(Constant.postCode + '/' + zipCode)
  }

  deactivateUser(userId: any) {
    return this.apiService.postData(Constant.deactivateUser + '/' + userId)
  }

  getUserProfile(userId: any) {
    return this.apiService.getData(Constant.userProfile + '/' + userId)
  }

  userProfile(payload) {
    return this.apiService.postData(Constant.userProfileImage, payload)
  }

  getLoggedInUserInfoWithAccessDetails(userId: any, isDeviceMenu: any) {
    return this.apiService.getData(Constant.userLoginAccessInfo + '/' + userId + '/access/' + isDeviceMenu)
  }

  updateUserDetails(payload: any) {
    return this.apiService.putData(Constant.updateUserProfile, payload)
  }
  getAccessControlRoleDetails(userId: any) {
    return this.apiService.getData(Constant.accessControlRole + '/' + userId)
  }

  updateAccessControlDetails(payload: any) {
    return this.apiService.postData(Constant.accessControlPost, payload);
  }

  documentUpload(selectDocumentImage:any, containerName:any, fileName:any) {
    const formData: FormData = new FormData();
    formData.append('FormFile', selectDocumentImage);
    return this.apiService.postFileUpload(Constant.documentUpload + '?ContainerName=' + containerName + '&FileName=' + fileName, formData);
  }

  cleanFileName(files, guid): string {
    const fileNameWithExtension = files.name;
  
    // Split the name into name and extension parts
    const fileParts = fileNameWithExtension.split('.');
    const extension = fileParts.pop();
    const baseName = fileParts.join(''); // Join remaining parts to get the base name
  
    // Clean the base name by removing everything except letters and numbers
    const cleanedBaseName = baseName.replace(Validators_Patterns.EXTRA_SPACE_REMOVE, '');
  
    // Return the cleaned file name with the original extension
    return `${cleanedBaseName}-${guid}.jpeg`;
  }

  encodedFileName(files, guid): string{
    const fileNameWithExtension = files?.name;
  
    // Split the name into name and extension parts
    const fileParts = fileNameWithExtension?.split('.');
    const extension = fileParts.pop();
    const baseName = fileParts.join(''); // Join remaining parts to get the base name
  
    // Clean the base name by removing everything except letters and numbers
    const cleanedBaseName = baseName.replace(Validators_Patterns.EXTRA_SPACE_REMOVE, '');
  
    // Return the cleaned file name with the original extension
    return encodeURIComponent(`${cleanedBaseName}-${guid}.jpeg`);
  }

  setUserDetails(value){
    this.userDetails.next(value); 
  }

  feedBackSave(payload) {
    return this.apiService.postData(Constant.feedBackSave, payload)
  }

  loginAs(userId) {
    return this.apiService.getData(Constant.loginAs + '/' + userId)
  }


  commonDocumentUpload(event: any): Promise<File | null> {
    return new Promise((resolve, reject) => {
      const maxSize = 5 * 1024 * 1024; 
      const file = event.target.files[0];
      const validExtensions = [FileType.JPG, FileType.JPEG, FileType.PNG, FileType.HEIC, FileType.HEIF];

      if (!file || file.size > maxSize) {
        this.snackBar.openSnackBar(this.translate.instant('EXCEED'), SnackBar.ERROR);
        resolve(null);
        return;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!validExtensions.includes(fileExtension)) {
        this.snackBar.openSnackBar(this.translate.instant('INVALID_FILE'), SnackBar.ERROR);
        resolve(null);
        return;
      }

      if (fileExtension === FileType.HEIC || fileExtension === FileType.HEIF) {
        this.snackBar.convertToJPEG(file)
          .then((jpegBlob) => {
            const jpegFile = new File([jpegBlob], file.name.replace(/\..+$/, ".jpg"), { type: "image/jpeg" });
            resolve(jpegFile);
          })
          .catch((error) => {
            console.error("Failed to convert HEIC to JPG: ", error);
            this.snackBar.openSnackBar(this.translate.instant('CONVERSION_FAILED'), SnackBar.ERROR);
            resolve(null);
          });
      } else {
        resolve(file);
      }
    });
  }


  initMap(googleMapData, formGroup, inputId, isPickupDropService?:any): void {
    let isSelectedFromDropdown = false;
    let timeoutId: any;

    const map = document.getElementById('map') ? new google.maps.Map(document.getElementById('map'), {
      center: googleMapData[0],
      zoom: 18,
      mapTypeId: google.maps.MapTypeId.SATELLITE
    }) : '';

    const card = document.getElementById('pac-card') as HTMLElement;
    const input = inputId;
    const options = {
      fields: ['formatted_address', 'geometry', 'name', 'address_components'],
      strictBounds: false,
      componentRestrictions: { country: 'IN' }
    };

    map.controls[google.maps.ControlPosition.TOP_LEFT]?.push(card);
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.bindTo('bounds', map);

    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById('infowindow-content') as HTMLElement;

    infowindow.setContent(infowindowContent);

    if(googleMapData[0]?.lat && googleMapData[0]?.lng){
      this.marker = new google.maps.Marker({
        position: { lat: googleMapData[0].lat, lng: googleMapData[0].lng },
        map: map,
        draggable: true,
        anchorPoint: new google.maps.Point(0, -29)
      });
    }

    const debounce = (func: Function, wait: number) => {
      return function(...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), wait);
      };
    };
  
    const handleInputChange = () => {
      isSelectedFromDropdown = false;
    };
  
    input?.addEventListener('input', debounce(handleInputChange, 300));

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      this.marker?.setVisible(false);

      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        window.alert(`No details available for input: '${place.name}'`);
        return;
      }

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      this.marker?.setPosition(place.geometry.location);
      this.marker?.setVisible(true);

      if(isPickupDropService){
        formGroup.controls['pickLongitude']?.setValue(Number(longitude))
        formGroup.controls['pickLatitude']?.setValue(Number(latitude))
      } else {
        formGroup.controls['longitude']?.setValue(Number(longitude))
        formGroup.controls['latitude']?.setValue(Number(latitude))
      }
      
      this.getPinCode(Number(latitude), Number(longitude), place, infowindowContent, infowindow, map, formGroup, isPickupDropService);
    });


    input?.addEventListener('change', () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (!isSelectedFromDropdown) {
          if (isPickupDropService) {
            formGroup.controls['pickAddress1'].setValue('');
          } else {
            formGroup.controls['address1'].setValue('');
          }
        }
      }, 300);
    });


   if(this.marker){
     google.maps.event?.addListener(this.marker, 'dragend', (event) => {
       this.handleMarkerDragEnd(event, formGroup, isPickupDropService);
     });
   }
  }


  async getPinCode(latitude, longitude, place, infowindowContent, infowindow, map, formGroup, isPickupDropService) {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${environments.googleAPIkey}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      
      if (data.status === 'OK' && data.results.length > 0) {         
        const addressComponents = data.results[0].address_components;
        const postalCodeComponent = addressComponents.find((component: any) =>
          component.types.includes('postal_code'));

        let landmark = '';
        let address2 = '';

        for (const component of place.address_components) {
          const types = component.types;
          // if (types.includes('landmark') || types.includes('premise') || types.includes('point_of_interest')) {
          //   landmark = component.long_name;
          // }

          // if(types.includes('route') || types.includes('sublocality_level_1') || types.includes('sublocality')){
          //   address2 = component.long_name
          // }
          if (types.includes('route') || types.includes('landmark') || types.includes('point_of_interest')) {
            landmark = component.long_name;
          }
  
          if(types.includes('neighborhood') || types.includes('sublocality_level_1') || types.includes('sublocality')){
            address2 = component.long_name
          }
        }

        if (place.name && place.formatted_address) {

          if(isPickupDropService) {
            formGroup.controls['pickAddress1'].setValue(place.name);
            formGroup.controls['pickAddress2'].setValue(address2);
            formGroup.controls['pickPostCode'].setValue(postalCodeComponent.long_name);
            formGroup.controls['pickLandmark'].setValue(landmark);
          } else {
            formGroup.controls['address1'].setValue(place.name);
            formGroup.controls['address2'].setValue(address2);
            formGroup.controls['postCode'].setValue(postalCodeComponent.long_name);
            formGroup.controls['landmark'].setValue(landmark);
          }

          (infowindowContent?.querySelector('#place-name') as HTMLElement).textContent = place.name;
          (infowindowContent?.querySelector('#place-address') as HTMLElement).textContent = place.formatted_address;
      
          infowindow.open(map, this.marker);
        }
      } else {
        throw new Error('Unable to find coordinates for the provided address.');
      }

    } catch (error) {
      return null;
    }
  }

  handleMarkerDragEnd(event, formGroup, isPickupDropService) {
    const latLng = event.latLng;
    const lat = latLng.lat();
    const lng = latLng.lng();

    if(isPickupDropService){
      formGroup.controls['pickLongitude']?.setValue(Number(lng))
      formGroup.controls['pickLatitude']?.setValue(Number(lat))
    } else {
      formGroup.controls['longitude'].setValue(Number(lng))
      formGroup.controls['latitude'].setValue(Number(lat))
    }
  }


  async latitudeLongitude(city: string, state: string, pinCode: string) {
    const address = `${city}, ${state}, ${pinCode}`;
    const apiKey = environments.googleAPIkey;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        
        return {
          lat: location.lat,
          lng: location.lng
        };
      } else {
        throw new Error('Unable to find coordinates for the provided address.');
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  async getLatLng(formGroup: any, isPickupDrop?:any): Promise<any> {
    let latLngValue: any;

    if(isPickupDrop) {
      if (!(formGroup.controls['pickLatitude'].value && formGroup.controls['pickLongitude'].value)) {
        latLngValue = await this.latitudeLongitude(
          formGroup.controls['pickCity'].value,
          formGroup.controls['pickState'].value,
          formGroup.controls['pickPostCode'].value
        );
      }
    } else {
      if (!(formGroup.controls['latitude'].value && formGroup.controls['longitude'].value)) {
        latLngValue = await this.latitudeLongitude(
          formGroup.controls['city'].value,
          formGroup.controls['state'].value,
          formGroup.controls['postCode'].value
        );
      }
    }

    return latLngValue;
  }


  convertToSingleSpace(input: string): string {
    return input?.trimStart()?.replace(/\s\s+/g, ' ');
  }


  requestUserOTP(payload) {
    return this.apiService.postData(Constant.requestUserOtp, payload)
  }

  requestVerifyOTP(payload) {
    return this.apiService.postData(Constant.verifyUserOtp, payload)
  }
}
