import { Injectable, OnInit } from '@angular/core';
import { ServiceStatus } from '../constants/servicestatus';
import { SnackbarComponent } from 'src/app/pages/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FileType, ServiceTypeCode, SnackBar, StorageItemType, invoiceStatusCode } from 'src/app/shared/constants/storageitem';
import { Images } from '../constants/images';
import heic2any from 'heic2any';
import { Observable, from } from 'rxjs';
import { StorageService } from './storage.service';
import { RoleCode } from '../constants/permissioncode';

@Injectable({
  providedIn: 'root'
})
export class BikeStatusService implements OnInit {

  readonly serviceStatus = ServiceStatus;
  readonly ServiceTypeCode = ServiceTypeCode;
  readonly invoiceStatusCode = invoiceStatusCode;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackBar: any;

  constructor(private _snackBar: MatSnackBar,
    // private snackBar: MatSnackBar,
    private translate: TranslateService,
    private storage : StorageService
  ) { }
  ngOnInit(): void {
  }

  serviceDetailBtn(item) {
    const bikeStatuses = [
      ServiceStatus.Booked,
      ServiceStatus.Invoiced,
      ServiceStatus.PaymentDue,
      ServiceStatus.PickUpPending,
      ServiceStatus.PickUpApproved,
      ServiceStatus.PickUpRejected,
      ServiceStatus.ApprovalPending,
      ServiceStatus.InProgress,
      ServiceStatus.Complete,
      ServiceStatus.Reject,
      ServiceStatus.Cancel,
      ServiceStatus.Expired,
    ];

    return bikeStatuses.includes(item?.statusCode);
  }

  cancelBookingBtn(item) {
    const bikeStatuses = [
      ServiceStatus?.Complete,
      ServiceStatus?.Invoiced,
      ServiceStatus?.PaymentDue,
      ServiceStatus?.Reject,
      ServiceStatus?.Cancel,
      ServiceStatus?.InProgress,
      ServiceStatus?.PickUpRejected,
      ServiceStatus?.Expired,
      ServiceStatus?.PickUpApproved
    ];

    const userDetails = JSON.parse(JSON.parse(this.storage.getObject(StorageItemType.USER_PROFILE)));
    if(userDetails?.roleCode === RoleCode.GarageOwner) {
      bikeStatuses.push(ServiceStatus.Booked, ServiceStatus.ApprovalPending)
    }

    return !bikeStatuses.includes(item?.statusCode);
  }

  jobCardBtn(item) {
    const bikeStatuses = [
      ServiceStatus?.InProgress,
      // ServiceStatus.OnHold,
      ServiceStatus?.Complete,
      ServiceStatus?.Invoiced,
      ServiceStatus?.PaymentDue,
      // ServiceStatus?.PickUpPending,
      // ServiceStatus?.PickUpApproved,
      // ServiceStatus?.PickUpRejected
    ]

    return bikeStatuses.includes(item?.statusCode);
  }

  invoiceBtn(item) {
    // Save Job Card Btn, Generate Invoice Btn and Save Invoice Btn
    const bikeStatuses = [
      ServiceStatus.OnHold,
      ServiceStatus?.Complete,
      ServiceStatus?.Reject,
      ServiceStatus?.Cancel
    ]

    return !bikeStatuses.includes(item?.statusCode);
  }

  saveInvoice(item) {
    const bikeStatuses = [
      ServiceStatus.OnHold,
      ServiceStatus?.Complete,
      ServiceStatus?.Reject,
      ServiceStatus?.Cancel,
      ServiceStatus?.PaymentDue,
    ]

    return !bikeStatuses.includes(item?.statusCode);
  }

  bookingBtn(item) {
    const bikeStatuses = [
      ServiceStatus?.Complete,
      ServiceStatus?.Reject,
      ServiceStatus?.Cancel
    ]

    return bikeStatuses.includes(item?.statusCode);
  }

  writeReview(item) {
    const bikeStatuses = [
      ServiceStatus?.Complete
    ]

    return bikeStatuses.includes(item?.statusCode);
  }

  openSnackBar(message: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  lengthValidator(length: number, type: 'max' | 'min') {
    return (control: FormControl) => {
      const value = control?.value;
      const condition = type === 'max' ? value?.toString()?.length > length : value?.toString()?.length < length;
      return condition ? { [type + 'length']: true } : null;
    };
  }

  maxLengthValidation(event: any, maxValue) {
    const input = event.target as HTMLInputElement;
    const trimmedValue = input.value.replace(/\D/g, '');
    const maxLength = maxValue;
    if (trimmedValue.length > maxLength) {
      input.value = trimmedValue.slice(0, maxLength);
    }
  }

  addedSnackBar() {
    this.snackBar.openSnackBar(this.translate.instant('ADDED'), SnackBar.SUCCESS)
  }

  updateSnackBar() {
    this.snackBar.openSnackBar(this.translate.instant('UPDATED'), SnackBar.SUCCESS)
  }

  deleteSnackBar() {
    this.snackBar.openSnackBar(this.translate.instant('DELETED'), SnackBar.SUCCESS)
  }

  wrongSnackBar() {
    this.snackBar.openSnackBar(this.translate.instant('WRONG'), SnackBar.SUCCESS)
  }

  dateFilter = (date: Date | null): boolean => {
    if (!date) return false;
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return date.setHours(0,0,0,0) <= today.setHours(0,0,0,0) && date.setHours(0,0,0,0) <= minDate.setHours(0,0,0,0);
  };

  setDefaultImage(event: any) {
    event.target.src = Images.IMAGE_NOT_AVAILABLE;
  }


  async displayImage(heicFile: File) {
    const jpegBlob = await this.convertToJPEG(heicFile);
    const imageUrl = URL.createObjectURL(jpegBlob);
    return imageUrl;
  }

  async convertToJPEG(heicFile: File): Promise<any> {
    return await heic2any({
      blob: heicFile,
      toType: FileType.Image_JPEG,
    });
  }

  displayImageAsObservable(heicFile: File): Observable<string> {
    return from(this.displayImage(heicFile));
  }


  removeSpecialCharacter() {
    const invalidChars = ['-', '+', 'e', '.'];
    // Get the current value of the input field
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove any invalid characters
    value = value.split('').filter(char => !invalidChars.includes(char)).join('');

    // Update the input field's value
    inputElement.value = value;
  }
}


